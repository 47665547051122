import { combineReducers } from "redux";
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";
import databaseReducer from "./databaseReducer";
import currentReducer from "./currentReducer";
import reportReducer from "./reportReducer";
import report2Reducer from "./report2Reducer";
import controlReducer from "./controlReducer";
import exportReducer from "./exportReducer";

const rootReducer = combineReducers({
  ui: uiReducer,
  userState: userReducer,
  database: databaseReducer,
  current: currentReducer,
  reportsState: reportReducer,
  reports2State: report2Reducer,
  control: controlReducer,
  exportState: exportReducer,
});

export default rootReducer;
