import React from "react";

import LocationButton from "./location/LocationButton";

import { useSelector } from "react-redux";
import Loader from "../Loader";

const LocationBar = ({ extended, currentPage }) => {
  const database = useSelector((state) => state.database);
  const ui = useSelector((state) => state.ui);
  const current = useSelector((state) => state.current);

  const filteredData = database.shops.filter(
    (item) =>
      item.company_name.toLowerCase().indexOf(ui.locationFilter.toLowerCase()) >
      -1
  );

  const trimedData = currentPage === 2 ? filteredData.slice(1) : filteredData;

  if (!database.shops || database.shops.length === 0) {
    return <Loader location={true} />;
  }

  return (
    <div className={`location-bar ${extended ? "location-bar-extended" : ""}`}>
      {trimedData.map((location) => (
        <LocationButton
          key={location.yardiid}
          location={location}
          currentCompanyId={current.currentCompanyId}
          currentPage={currentPage}
        />
      ))}
    </div>
  );
};

export default LocationBar;
