import React from "react";

import gear from "../assets/gear.svg";

const Busy = () => {
  return (
    <div className="busy">
      <img src={gear} alt="gear" />
      <p>Working</p>
    </div>
  );
};

export default Busy;
