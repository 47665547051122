import React from "react";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useSelector } from "react-redux";
const Row = ({ item }) => {
  const ui = useSelector((state) => state.ui);
  //const database = useSelector((state) => state.database);

  // const normalAccount = item.account === null ? "" : item.account;
  // const normalAccountDescription =
  // item.account_description === null ? "" : item.account_description;

  return (
    <Tr className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}>
      <Td className="report-table-td">{item.company_id}</Td>
      <Td className="report-table-td">{item.resident_id}</Td>
      <Td className="report-table-td">{item.yardi_id}</Td>
      <Td className="report-table-td">{item.comunity_name}</Td>
      <Td className="report-table-td">{item.service_name}</Td>
      <Td className="report-table-td">{item.resident_name}</Td>
      <Td className="report-table-td">{item.unit_id}</Td>
      <Td className="report-table-td">{item.lightspeed_id}</Td>
    </Tr>
  );
};

export default Row;
