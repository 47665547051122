import React from "react";

import excell from "../../../assets/excell.png";

const Excell = ({ onClick }) => {
  return (
    <div onClick={onClick} className="export-to-excell-button">
      
    </div>
  );
};

export default Excell;
