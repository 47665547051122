const initState = {
  shops: [],
  residents: [],
  distinct: [],
};

const databaseReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SHOPS":
      return { ...state, shops: action.payload };
    case "SET_RESIDENTS":
      return { ...state, residents: action.payload };
    case "SET_DISTINCT":
      return { ...state, distinct: action.payload };
    default:
      return state;
  }
};

export default databaseReducer;
