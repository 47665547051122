import React, { useState } from "react";

import SideBar from "./home/SideBar";
import LocationBar from "./home/LocationBar";
import LocationTopBar from "./home/LocationTopBar";
import ControlPanel1 from "./home/ControlPanel1";
import ControlPanel2 from "./home/ControlPanel2";
import ControlPanel3 from "./home/ControlPanel3";
import ControlPanelExport from "./home/ControlPanelExport";
import ControlPanel4 from "./home/ControlPanel4";
import ControlPanelError from "./home/ControlPanelError";

//import ControlPanelSimple from "./home/ControlPanelSimple";
import TableContent from "./home/TableContent";
import Dash from "./home/Dash";

import Busy from "./Busy";
import { useSelector } from "react-redux";

import dots from "../assets/dots.svg";

const Home = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [extended, setExtended] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);
  const ui = useSelector((state) => state.ui);

  return (
    <div className="home">
      {showSideBar && (
        <SideBar currentPage={currentPage} setCurrentPage={setCurrentPage} />
      )}

      <div
        className="centered side-bar-toggle"
        onClick={(_) => {
          setShowSideBar((old) => !old);
        }}
      >
        <img src={dots} alt="burger dots" />
      </div>

      {currentPage === 0 && <Dash />}
      {currentPage > 0 && (
        <div className="main-section relative">
          {(ui.downloadShops ||
            ui.downloadReports ||
            ui.downloadReports2 ||
            ui.downloadResidents ||
            ui.downloadDistinct) && <Busy />}
          <LocationTopBar setExtended={setExtended} extended={extended} />
          <LocationBar currentPage={currentPage} extended={extended} />
          {currentPage === 1 && <ControlPanel1 />}
          {currentPage === 11 && <ControlPanel2 />}
          {currentPage === 50 && <ControlPanel3 />}
          {currentPage === 200 && <ControlPanelExport />}
          {currentPage === 100 && <ControlPanelError />}
          {currentPage === 51 && <ControlPanel4 />}
          {currentPage === 3 && <ControlPanel1 />}
          <TableContent currentPage={currentPage} />
        </div>
      )}
    </div>
  );
};

export default Home;
