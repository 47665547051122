import React from "react";
import { useSelector } from "react-redux";

import logoOld from "../assets/logoOld.png";
import logo from "../assets/logo.png";

import Login from "./login/Login";
import Recover from "./login/Recover";

const LoginWrapper = ({ recover }) => {
  const ui = useSelector((state) => state.ui);
  return (
    <div className="login-wrapper">
      <div
        className={`login-box-bg ${
          ui.theme === "flame" ? "" : "login-box-bg-dark"
        }`}
      >
        <div
          className={`login-box ${
            ui.theme === "flame" ? "" : "login-box-dark"
          }`}
        >
          <div
            className={`logo-wrapper ${
              ui.theme === "flame" ? "" : "logo-wrapper-dark"
            }`}
          >
            <img src={ui.theme === "flame" ? logoOld : logo} alt="logo" />
          </div>
          {recover ? <Recover /> : <Login />}
        </div>
      </div>
    </div>
  );
};

export default LoginWrapper;
