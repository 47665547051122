import React from "react";
import { Card } from "@lightspeed/flame/Card";

import { ReactComponent as Location } from "../../../assets/location.svg";

import * as databaseActions from "../../../redux/actions/databaseActions";
import * as uiActions from "../../../redux/actions/uiActions";
import { useDispatch } from "react-redux";

const LocationButton = ({ location, currentCompanyId, currentPage }) => {
  const dispatch = useDispatch();
  const active = location.company_id === currentCompanyId;

  console.log("Log 3 (currentCompanyId)--> ", currentCompanyId)
  console.log("Log 3 (location.company_id)--> ", location.company_id)

  const handleClick = () => {
    if (currentPage === 1) {
      console.log("currentPage === 1");
      dispatch(databaseActions.setCurrentCompanyId(location.company_id));
      dispatch(uiActions.setSelectValue(null));
      dispatch(databaseActions.getDistinct(location.company_id));
      dispatch(databaseActions.setReportsMiddlewarePriced([]));
      dispatch(databaseActions.getReportsMiddlewarePriced());
    } else if (currentPage === 11) {
      dispatch(databaseActions.setCurrentCompanyId(location.company_id));
      dispatch(uiActions.setSelectValue(null));
      dispatch(databaseActions.getDistinct(location.company_id));
      dispatch(databaseActions.setReportsMiddlewareZeroPriced([]));
      dispatch(databaseActions.getReportsMiddlewareZeroPriced());
    } else if (currentPage === 2) {
      dispatch(databaseActions.setCurrentCompanyId(location.company_id));
      dispatch(databaseActions.setResidents([]));
      dispatch(databaseActions.getResidents(location.company_id));
    } else if (currentPage === 3) {
      dispatch(databaseActions.setCurrentCompanyId(location.company_id));
      dispatch(databaseActions.setReports2([]));
      dispatch(databaseActions.getReports2(location.company_id));
    } else if (currentPage === 50) {
      dispatch(databaseActions.setCurrentCompanyId(location.company_id));
      dispatch(uiActions.setSelectValue(null));
      dispatch(databaseActions.getDistinct(location.company_id));
      dispatch(databaseActions.setReportsYardiPriced([]));
      dispatch(databaseActions.getReportsYardiPriced());
    } else if (currentPage === 100) {
      dispatch(databaseActions.setCurrentCompanyId(location.company_id));
      dispatch(uiActions.setSelectValue(null));
      //dispatch(databaseActions.getDistinct(location.company_id));
      dispatch(databaseActions.setReportsError([]));
      dispatch(databaseActions.getReportsError());
    } else if (currentPage === 51) {
      dispatch(databaseActions.setCurrentCompanyId(location.company_id));
      dispatch(uiActions.setSelectValue(null));
      dispatch(databaseActions.getDistinct(location.company_id));
      dispatch(databaseActions.setReportsYardiZeroPriced([]));
      dispatch(databaseActions.getReportsYardiZeroPriced());
    } else if (currentPage === 200) {
      dispatch(databaseActions.setCurrentCompanyId(location.company_id));
      dispatch(uiActions.setSelectValue(null));
      dispatch(databaseActions.getDistinct(location.company_id));
      dispatch(databaseActions.setReportsYardiExport([]));
      dispatch(databaseActions.getReportsYardiExport());
    }
    
  };

  const dynamicClass = active ? "inverted" : "";
  return (
    <Card
      onClick={handleClick}
      top="true"
      className={`location-button ${dynamicClass}`}
    >
      <Location />
      {location.company_name}
    </Card>
  );
};

export default LocationButton;
