import React from "react";
import { useDispatch } from "react-redux";
import * as dbActions from "../../../redux/actions/databaseActions";
import * as uiActions from "../../../redux/actions/uiActions";

const SideList = ({ LocationButton, label, setCurrentPage, currentPage, pageN, svg }) => {
  const dispatch = useDispatch();

  return (
    <div className="side-list-button">
      <div
        onClick={async (_) => {
          setCurrentPage(pageN);
          if (pageN === 11) {
            //console.log("getReportsMiddlewareZeroPriced");
            dispatch(uiActions.setDownloadReports(true));
            await dispatch(dbActions.getShopsPriced(1,pageN,pageN));
            dispatch(dbActions.setReportsMiddlewareZeroPriced([]));
            dispatch(dbActions.getReportsMiddlewareZeroPriced());
            dispatch(dbActions.setDistinct([]));
            dispatch(dbActions.getDistinct());
          }
          if (pageN === 1) {
            //console.log("getReportsMiddlewarePriced1");
            dispatch(uiActions.setDownloadReports(true));
            await dispatch(dbActions.getShopsPriced(1,pageN,pageN));
            dispatch(dbActions.setReportsMiddlewarePriced([]));
            dispatch(dbActions.getReportsMiddlewarePriced());
            dispatch(dbActions.setDistinct([]));
            dispatch(dbActions.getDistinct());
          }
          if (pageN === 50) {
            dispatch(uiActions.setDownloadReports(true));
            await dispatch(dbActions.getShopsPriced(1,pageN,pageN));
            dispatch(dbActions.setReportsYardiPriced([]));
            dispatch(dbActions.getReportsYardiPriced());
            dispatch(dbActions.setDistinct([]));
            dispatch(dbActions.getDistinct());
          }
          if (pageN === 200) {
            dispatch(uiActions.setDownloadReports(true));
            await dispatch(dbActions.getShopsExport(1,pageN,pageN));
            dispatch(dbActions.setReportsYardiExport([]));
            dispatch(dbActions.getReportsYardiExport());
            dispatch(dbActions.setDistinct([]));
            dispatch(dbActions.getDistinct());
          }
          if (pageN === 100) {
            dispatch(dbActions.setReportsError([]));
            dispatch(dbActions.getReportsError());
          }
          if (pageN === 51) {
            console.log("getReportsYardiZeroPriced");
            dispatch(uiActions.setDownloadReports(true));
            await dispatch(dbActions.getShopsPriced(1,pageN,pageN));
            dispatch(dbActions.setReportsYardiZeroPriced([]));
            dispatch(dbActions.getReportsYardiZeroPriced());
            dispatch(dbActions.setDistinct([]));
            dispatch(dbActions.getDistinct());
          }
          if (pageN === 2) {
            setCurrentPage("getResidents");
            dispatch(dbActions.setResidents([]));
            dispatch(dbActions.getResidents());
          }
        }}
        className={`side-label ${
          currentPage === pageN ? "side-list-active" : ""
        }`}
      >
        <img src={svg} alt="icon" className="side-svg" />
        <p>{label}</p>
      </div>
    </div>
  );
};

export default SideList;
