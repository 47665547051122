export const changeTheme = () => ({ type: "CHANGE_UI_THEME" });
export const setTheme = (theme) => ({ type: "SET_UI_THEME", payload: theme });
export const setNotify = (error, errorMsg) => ({
  type: "SET_NOTIFY",
  payload: { error, errorMsg },
});
export const setNotifyShow = (show) => ({
  type: "SET_NOTIFY_SHOW",
  payload: show,
});
export const setSearch = (query) => ({
  type: "SET_SEARCH",
  payload: query.toLowerCase(),
});
export const setOverLimit = (bool) => ({
  type: "SET_OVERLIMIT",
  payload: bool,
});
export const setMeals = (bool) => ({
  type: "SET_MEALS",
  payload: bool,
});
export const setOther = (bool) => ({
  type: "SET_OTHER",
  payload: bool,
});
export const setLocationFilter = (bool) => ({
  type: "SET_LOCATION_FILTER",
  payload: bool,
});
export const setDownloadShops = (bool) => ({
  type: "SET_DOWNLOAD_SHOPS",
  payload: bool,
});
export const setDownloadReports = (bool) => ({
  type: "SET_DOWNLOAD_REPORTS",
  payload: bool,
});
export const setDownloadReports2 = (bool) => ({
  type: "SET_DOWNLOAD_REPORTS_2",
  payload: bool,
});
export const setDownloadResidents = (bool) => ({
  type: "SET_DOWNLOAD_RESIDENTS",
  payload: bool,
});
export const setDownloadDistinct = (bool) => ({
  type: "SET_DOWNLOAD_DISTINCT",
  payload: bool,
});
export const setSelectValue = (selectValue) => ({
  type: "SET_SELECT_VALUE",
  payload: selectValue,
});
export const setSelectDate = (dateValue) => ({
  type: "SET_SELECT_DATE",
  payload: dateValue,
});
export const setExportReports = (reports) => ({
  type: "SET_EXPORT_REPORTS",
  payload: reports,
});
