//import React, { useState } from "react";
import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";
import TBody from "./reportTable/TBody";
import Loader from "../../Loader";
import { useSelector } from "react-redux";
import TBodyYardiPriced from "./reportTable/TBodyYardiPriced";

const ReportTableYardiPriced = () => {
  const ui = useSelector((state) => state.ui);
  
  if (ui.downloadReports) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table className={ui.theme === "flame" ? "" : "root-table-dark"}>
        <Thead>
          <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
            <Th className="table-th-sticky" width="10%">
              CompanyId YP
            </Th>
            <Th className="table-th-sticky" width="20%">
              PropertyName
            </Th>
            <Th className="table-th-sticky" width="20%">
              ResidentId
            </Th>
            <Th className="table-th-sticky" width="10%">
              ResidentName
            </Th>
            <Th className="table-th-sticky" width="10%">
              CareLevel
            </Th>
            <Th className="table-th-sticky" width="10%">
              Accountprofile
            </Th>
            <Th className="table-th-sticky" width="10%">
              CreationTime
            </Th>
            <Th className="table-th-sticky" width="10%">
            BeverageAlcoholic
            </Th>
            <Th className="table-th-sticky" width="10%">
            BeverageJuices
            </Th>
            <Th className="table-th-sticky" width="10%">
            BeverageSodas
            </Th>
            <Th className="table-th-sticky" width="10%">
            BeverageTeasOther
            </Th>
            <Th className="table-th-sticky" width="10%">
            Dessert
            </Th>
            <Th className="table-th-sticky" width="10%">
            Meals
            </Th>
            <Th className="table-th-sticky" width="10%">
            Misc
            </Th>
            <Th className="table-th-sticky" width="10%">
            PricedMeals
            </Th>
            <Th className="table-th-sticky" width="10%">
            Sides
            </Th>
            <Th className="table-th-sticky" width="10%">
            Soup
            </Th>
            <Th className="table-th-sticky" width="10%">
            Starters
            </Th>
            <Th className="table-th-sticky" width="10%">
            SVCToGoDelivery
            </Th>
            <Th className="table-th-sticky" width="10%">
            Tax
            </Th>
            <Th className="table-th-sticky" width="10%">
            TotalTipIncluded
            </Th>
            <Th className="table-th-sticky" width="10%">
            RoleId
            </Th>

          </Tr>
        </Thead>
        <TBodyYardiPriced />
      </Table>
    </Card>
  );
};

export default ReportTableYardiPriced;
