// const customSortCompanyName = (a, b) => {
//   const companyA = a.company_name.toLowerCase();
//   const companyB = b.company_name.toLowerCase();

//   let comparison = 0;
//   if (companyA > companyB) {
//     comparison = 1;
//   } else if (companyA < companyB) {
//     comparison = -1;
//   }
//   return comparison;
// };

const initState = {
  currentCompanyId: 0,
};

const currentReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CURRENT_COMPANY_ID":
      return { ...state, currentCompanyId: action.payload };
    default:
      return state;
  }
};

export default currentReducer;
