import React from "react";

import { getDate } from "../../../../utils/dateUtil";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useSelector } from "react-redux";
const Row = ({ item }) => {
  const ui = useSelector((state) => state.ui);


  return (
    <Tr
      //className="report-table-tr"
      className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td className="report-table-td">{item.ResidentId}</Td>
      <Td className="report-table-td">{item.ResidentName}</Td>
      <Td className="report-table-td">{item.consumer_email}</Td>
      <Td className="report-table-td">{item.error_description}</Td>

      </Tr>
  );
};

export default Row;
