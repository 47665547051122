import React from "react";

import { getDate } from "../../../../utils/dateUtil";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useSelector } from "react-redux";
const Row = ({ item }) => {
  const ui = useSelector((state) => state.ui);
  //const database = useSelector((state) => state.database);

  // const normalAccount = item.account === null ? "" : item.account;
  // const normalAccountDescription =
  //   item.account_description === null ? "" : item.account_description;

  console.log("2222");
  return (
    <Tr
      //className="report-table-tr"
      className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td className="report-table-td">{item.CompanyId}</Td>
      <Td className="report-table-td">{item.location_id}</Td>
      <Td className="report-table-td">{item.resident_id}</Td>
      <Td className="report-table-td">{item.ResidentName}</Td>
      <Td className="report-table-td">{item.ReceiptId}</Td>
      <Td className="report-table-td">{item.create_time}</Td>
      <Td className="report-table-td">{item.total}</Td>
      <Td className="report-table-td">{item.chargecode}</Td>

      


    </Tr>
  );
};

export default Row;
