import React, { useState } from "react";
import styled from "@emotion/styled";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";

import { Card } from "@lightspeed/flame/Card";
import { useSelector } from "react-redux";

import Loader from "../../Loader";

const StyledCard = styled(Card)`
  position: relative;
  overflow: auto;
  height: 100%;
  * {
    font-family: Helvetica, sans-serif;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const PivotTable = () => {
  const { reports2 } = useSelector((state) => state.reports2State);
  const { downloadReports2 } = useSelector((state) => state.ui);
  const [state, setState] = useState(reports2);
  const rows = [
    "CompanyId",
    "PropertyName",
    "ResidentId",
    "ResidentName",
    "CareLevel",
    "AccountProfile",
    "ReceiptId",
    "PaymentId",
    "PaymentTypeName",
    "timeOfCloseAndPaid",
    "BeverageAlcoholic",
    "BeverageJuices",
    "BeverageSodas",
    "BeverageTeasOther",
    "Dessert",
    "Meals",
    "Misc",
    "PricedMeals",
    "Sides",
    "Soups",
    "Starters",
    "SVCToGoDelivery",
    "TotalTipExcluded",
    "Tips",
    "TAX",
    "TotalTipIncluded",
    "Shift",
    "Cover"
  ];
  const cols = [
  ];

  const val = [
    "amount"
  ];
  
  const aggregatorName = "Sum";
  

  if (downloadReports2) {
    return <Loader />;
  }

  return (
    <StyledCard>
      <Container>
        <PivotTableUI
          unusedOrientationCutoff={Infinity}
          data={reports2}
          onChange={(s) => setState(s)}
          rows={rows}
          cols={cols}
          aggregatorName={aggregatorName}
          vals={val}
          {...state}
        />
      </Container>
    </StyledCard>
  );
};

export default PivotTable;
