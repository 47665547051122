import React from "react";

import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";

import Row from "./usersTable/Row";
import Loader from "../../Loader";

import { useSelector } from "react-redux";

const UsersTable = () => {
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);

  if (
    !database.residents ||
    database.residents.length === 0 ||
    !Array.isArray(database.residents)
  ) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table className={ui.theme === "flame" ? "" : "root-table-dark"}>
        <Thead>
          <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
            <Th className="table-th-sticky" width="10%">
              Company Id
            </Th>
            <Th className="table-th-sticky" width="10%">
              Resident Id
            </Th>
            <Th className="table-th-sticky" width="10%">
              Yardi Id
            </Th>
            <Th className="table-th-sticky" width="15%">
              Community Name
            </Th>
            <Th className="table-th-sticky" width="20%">
              Service Name
            </Th>
            <Th className="table-th-sticky" width="15%">
              Resident Name
            </Th>
            <Th className="table-th-sticky" width="5%">
              Unit Id
            </Th>
            <Th className="table-th-sticky" width="10%">
              Lightspeed Id
            </Th>
          </Tr>
        </Thead>
        <tbody className="t-body">
          {database.residents.map((item) => (
            <Row key={item.resident_id} item={item} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default UsersTable;
