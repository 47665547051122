import React from "react";

import { getDate } from "../../../../utils/dateUtil";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useSelector } from "react-redux";
const Row = ({ item }) => {
  const ui = useSelector((state) => state.ui);
  //const database = useSelector((state) => state.database);

  // const normalAccount = item.account === null ? "" : item.account;
  // const normalAccountDescription =
  //   item.account_description === null ? "" : item.account_description;

  console.log("2222");
  return (
    <Tr
      //className="report-table-tr"
      className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td className="report-table-td">{item.CompanyId}</Td>
      <Td className="report-table-td">{item.PropertyName}</Td>
      <Td className="report-table-td">{item.ResidentId}</Td>
      <Td className="report-table-td">{item.ResidentName}</Td>
      <Td className="report-table-td">{item.CareLevel}</Td>
      <Td className="report-table-td">{item.AccountProfile}</Td>
      <Td className="report-table-td">{item.timeOfCloseAndPaid}</Td>
      <Td className="report-table-td">{item.BeverageAlcoholic}</Td>
      <Td className="report-table-td">{item.BeverageJuices}</Td>
      <Td className="report-table-td">{item.BeverageSodas}</Td>
      <Td className="report-table-td">{item.BeverageTeasOther}</Td>
      <Td className="report-table-td">{item.Dessert}</Td>
      <Td className="report-table-td">{item.Meals}</Td>
      <Td className="report-table-td">{item.Misc}</Td>
      <Td className="report-table-td">{item.PricedMeals}</Td>
      <Td className="report-table-td">{item.Sides}</Td>
      <Td className="report-table-td">{item.Soups}</Td>
      <Td className="report-table-td">{item.Starters}</Td>
      <Td className="report-table-td">{item.SVCToGoDelivery}</Td>
      <Td className="report-table-td">{item.TAX}</Td>
      <Td className="report-table-td">{item.TotalTipIncluded}</Td>
      <Td className="report-table-td">{item.RoleId}</Td>
      


    </Tr>
  );
};

export default Row;
