const today = new Date();
const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
const yyyy = today.getFullYear();
const currentDate = yyyy + "-" + mm;

const initState = {
  selectValue: null,
  selectDate: currentDate,
};

const controlReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SELECT_VALUE":
      return { ...state, selectValue: action.payload };
    case "SET_SELECT_DATE":
      return { ...state, selectDate: action.payload };
    default:
      return state;
  }
};

export default controlReducer;
