//import React, { useState } from "react";
import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";
import TBody from "./reportTable/TBody";
import Loader from "../../Loader";
import { useSelector } from "react-redux";
import TBodyYardiZeroPriced from "./reportTable/TBodyYardiZeroPriced";

const ReportTableYardiZeroPriced = () => {
  const ui = useSelector((state) => state.ui);

  if (ui.downloadReports) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table className={ui.theme === "flame" ? "" : "root-table-dark"}>
        <Thead>
          <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
            <Th className="table-th-sticky">
              CompId YZP
            </Th>
            <Th className="table-th-sticky">
              PropertyName
            </Th>
            <Th className="table-th-sticky">
              ResidentId
            </Th>
            <Th className="table-th-sticky">
              Resident Name
            </Th>

            <Th className="table-th-sticky">
              FiscalId
            </Th>
            <Th className="table-th-sticky">
              CareLevel
            </Th>
            <Th className="table-th-sticky">
              AccountProfile
            </Th>
            <Th className="table-th-sticky">
            Payment
            </Th>
            <Th className="table-th-sticky">
            MealPlan
            </Th>
            
            <Th className="table-th-sticky">
            Date
            </Th>
            
            <Th className="table-th-sticky">
            Price
            </Th>
       
            <Th className="table-th-sticky">
            Shift
            </Th>
            <Th className="table-th-sticky">
            TOGO
            </Th>
            <Th className="table-th-sticky">
            Delivery
            </Th>
            <Th className="table-th-sticky">
            RTMeals
            </Th>
            <Th className="table-th-sticky">
            RTToGo
            </Th>
            <Th className="table-th-sticky">
            RTDelivery
            </Th>
            <Th className="table-th-sticky">
            RTG
            </Th>
            <Th className="table-th-sticky">
            RuleId
            </Th>
          </Tr>
        </Thead>
        <TBodyYardiZeroPriced />
      </Table>
    </Card>
  );
};

export default ReportTableYardiZeroPriced;
