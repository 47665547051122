import React from "react";

class GlobalError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ width: "100%", padding: "40px" }}>
          <h2 style={{ textAlign: "center", color: "#222", fontWeight: 400 }}>
            Something went wrong. <br /> Help us fix this error by reporting
            your last actrions.
          </h2>
        </div>
      );
    }

    return this.props.children;
  }
}

export default GlobalError;
