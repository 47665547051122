import React, { useEffect } from "react";

import RowMZP from "./RowMZP";

import { useSelector, useDispatch } from "react-redux";

import * as uiActions from "../../../../redux/actions/uiActions";

const TBodyMiddlewareZeroPriced = () => {
  const reportsState = useSelector((state) => state.reportsState);
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  
  const filteredReports = reportsState.reports.filter((item) => {
    let currentPass = 0;
    return 1;
    /*
    if (!ui.meals && !ui.other) return false;

    if (ui.overLimit) {
      currentPass += item.exceded > 0 ? 1 : 0;
    } else {
      currentPass += 1;
    }

    if (ui.meals && item.srv === "meal") {
      currentPass += 1;
    }

    if (ui.other && item.srv === "other") {
      currentPass += 1;
    }
 
    return currentPass > 1;
    */
  });


  useEffect(() => {
    dispatch(uiActions.setExportReports(filteredReports));
  }, [filteredReports, dispatch]);

  return (
    <tbody className="t-body">
      {filteredReports.map((item, ix) => (
        <RowMZP key={ix} item={item} />
      ))}
    </tbody>
  );
};

export default React.memo(TBodyMiddlewareZeroPriced);
