//import React, { useState } from "react";
import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";
import TBody from "./reportTable/TBody";
import Loader from "../../Loader";
import { useSelector } from "react-redux";

const ReportTable = () => {
  const ui = useSelector((state) => state.ui);

  if (ui.downloadReports) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table className={ui.theme === "flame" ? "" : "root-table-dark"}>
        <Thead>
          <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
            <Th className="table-th-sticky" width="10%">
              CompanyId
            </Th>
            <Th className="table-th-sticky" width="20%">
              PropertyName
            </Th>
            <Th className="table-th-sticky" width="20%">
              ResidentId
            </Th>
            <Th className="table-th-sticky" width="10%">
              ResidentName
            </Th>
            <Th className="table-th-sticky" width="10%">
              CareLevel
            </Th>
            <Th className="table-th-sticky" width="10%">
              Accountprofile
            </Th>
            <Th className="table-th-sticky" width="10%">
              ReceiptId
            </Th>
            <Th className="table-th-sticky" width="10%">
              PaymentId
            </Th>
            <Th className="table-th-sticky" width="10%">
              PaymentName
            </Th>
            <Th className="table-th-sticky" width="10%">
              CreationTime
            </Th>
            <Th className="table-th-sticky" width="30%">
            BeverageAlcoholic
            </Th>
            <Th className="table-th-sticky" width="10%">
            BeverageJuices
            </Th>
            <Th className="table-th-sticky" width="10%">
            BeverageSodas
            </Th>
            <Th className="table-th-sticky" width="10%">
            BeverageTeasOther
            </Th>
            <Th className="table-th-sticky" width="10%">
            Dessert
            </Th>
            <Th className="table-th-sticky" width="10%">
            Meals
            </Th>
            <Th className="table-th-sticky" width="10%">
            Misc
            </Th>
            <Th className="table-th-sticky" width="10%">
            Priced Meals
            </Th>
            <Th className="table-th-sticky" width="10%">
            Sides
            </Th>
            <Th className="table-th-sticky" width="10%">
            Soups
            </Th>
            <Th className="table-th-sticky" width="10%">
            Starters
            </Th>
            <Th className="table-th-sticky" width="10%">
            Service Fees (ToGo/Delivery)
            </Th>
            <Th className="table-th-sticky" width="10%">
            Total Reciept (Tip Exl)
            </Th>
            <Th className="table-th-sticky" width="10%">
            Tips
            </Th>
            <Th className="table-th-sticky" width="10%">
            Tax
            </Th>
            <Th className="table-th-sticky" width="10%">
            Total receipt Paid (Tip Incl)
            </Th>
            <Th className="table-th-sticky" width="10%">
              Food
            </Th>
            <Th className="table-th-sticky" width="10%">
              Number
            </Th>
          </Tr>
        </Thead>
        <TBody />
      </Table>
    </Card>
  );
};

export default ReportTable;
