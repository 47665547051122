import React from "react";
import { Button } from "@lightspeed/flame/Button";

import user from "../../../assets/user.svg";

const UserDispaly = () => {
  return (
    <div className="user-display">
      <div className="user-svg-wrapper">
        <img src={user} alt="user" />
      </div>
      <div className="flex" />
      <Button onClick={() => window.location.reload()}>Logout</Button>
    </div>
  );
};

export default UserDispaly;
