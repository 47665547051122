import React, { useState } from "react";
import { Button } from "@lightspeed/flame/Button";
import { Input } from "@lightspeed/flame/Input";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getUser } from "../../redux/actions/userActions";

const Login = () => {
  const dispatch = useDispatch();
  const [userValue, setUserValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [notification, setNotification] = useState({ show: false, msg: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userValue === "") {
      setNotification({ show: true, msg: "Username is required" });
      return;
    }
    if (passwordValue === "") {
      setNotification({ show: true, msg: "Password is required" });
      return;
    }

    dispatch(
      getUser({
        username: userValue,
        password: passwordValue,
        setNotification,
      })
    );
  };
  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <label htmlFor="user">Username</label>
      <Input
        name="user"
        type="text"
        value={userValue}
        placeholder="Username"
        onChange={(e) => setUserValue(e.target.value)}
        tabIndex="1"
      />
      <div className="password-recover-wrapper">
        <label htmlFor="password">Password</label>
        <Link tabIndex="4" to="/recover">
          Forgot password?
        </Link>
      </div>
      <Input
        name="password"
        type="password"
        value={passwordValue}
        onChange={(e) => setPasswordValue(e.target.value)}
        tabIndex="2"
      />
      {notification.show && <p className="form-notifi">{notification.msg}</p>}

      <div className="fl-mb-2">
        <Button
          style={{ marginTop: "32px" }}
          block="false"
          type="submit"
          fill="true"
          variant="primary"
          tabIndex="3"
        >
          Sign in
        </Button>
      </div>
    </form>
  );
};

export default Login;
