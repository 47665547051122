import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Card } from "@lightspeed/flame/Card";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

import { setLocationFilter } from "../../redux/actions/uiActions";

const LocationTopBar = ({ setExtended, extended }) => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const database = useSelector((state) => state.database);
  const handleClick = () => {
    setExtended((oldState) => !oldState);
  };

  return (
    <Card
      top="true"
      className={`location-top-bar ${
        ui.theme === "flame" ? "" : "location-top-bar-dark"
      }`}
    >
      <div className="flex" />
      {database.shops.length > 7 && (
        <Button size="small" type="submit" fill="true" onClick={handleClick}>
          {extended ? "Collapse Locations" : "Extend Locations"}
        </Button>
      )}
      <div className="input-wrapper">
        <Input
          className="input-custom-bg"
          value={ui.locationFilter}
          onChange={(e) => dispatch(setLocationFilter(e.target.value))}
          type="text"
          size="small"
          placeholder="Seach by Location"
        />
      </div>
    </Card>
  );
};

export default LocationTopBar;
