//import React, { useState } from "react";

import * as dbActions from "../../redux/actions/databaseActions";
import * as uiActions from "../../redux/actions/uiActions";

import { Checkbox } from "@lightspeed/flame/Checkbox";
import { Input } from "@lightspeed/flame/Input";
import { Card } from "@lightspeed/flame/Card";
import { Button } from "@lightspeed/flame/Button";

import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

const ControlPanel3 = () => {
  const database = useSelector((state) => state.database);
  const control = useSelector((state) => state.control);
  const current = useSelector((state) => state.current);
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const options = database.distinct.map((item) => ({
    value: String(item.resident_id),
    label: item.resident_name,
  }));

  const handleApply = () => {
    dispatch(dbActions.getReportsYardiExport(current.currentCompanyId));
  };

  const handleDateChange = (e) => {
    dispatch(uiActions.setSelectDate(e.target.value));
    dispatch(dbActions.getReportsYardiExport(current.currentCompanyId));
  };

  return (
    <div className="control-panel">
      <Card className="control-panel-card">
        <p className="select-title">Customer: </p>

        <div
          className={`select-wrapper ${
            ui.theme === "flame" ? "" : "dark-table-fake"
          }`}
        >
          <Select
            isClearable="true"
            className={`flex ${ui.theme === "flame" ? "" : "dark-select"}`}
            options={options}
            onChange={(e) => dispatch(uiActions.setSelectValue(e))}
            value={control.selectValue}
            placeholder="Select Customer"
          />
          <Button
            onClick={handleApply}
            style={{ marginLeft: "8px" }}
            size="small"
          >
            Apply
          </Button>
        </div>
        
        <div className="date-wrapper">
          <Input
            type="month"
            min="2018-03"
            value={control.selectDate}
            onChange={handleDateChange}
          />
        </div>
      </Card>
    </div>
  );
};

export default ControlPanel3;
