import React, { useEffect } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import LoginWrapper from "./components/LoginWrapper";

import { FlameTheme, FlameGlobalStyles } from "@lightspeed/flame/Core";

import { useSelector, useDispatch } from "react-redux";

import { setTheme } from "./redux/actions/uiActions";

const App = () => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const userState = useSelector((state) => state.userState);

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("theme"));
    if (storage) {
      dispatch(setTheme(storage));
    }
  }, [dispatch]);
  return (
    <FlameTheme themeName={ui.theme}>
      <FlameGlobalStyles themeName={ui.theme} />
      <BrowserRouter>
        {userState.userId === "" ? (
          <Switch>
            <Route exact path="/recover">
              <LoginWrapper recover />
            </Route>
            <Route path="/">
              <LoginWrapper />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path="/" component={Home} />
          </Switch>
        )}
      </BrowserRouter>
    </FlameTheme>
  );
};

export default App;
