import React from "react";

import { useDispatch } from "react-redux";
import { changeTheme } from "../../../redux/actions/uiActions";

const DarkMode = () => {
  const dispatch = useDispatch();
  return (
    <div onClick={() => dispatch(changeTheme())} className="dark-mode-button">
      Dark Mode
    </div>
  );
};

export default DarkMode;
