import React from "react";

import ReportTable from "./tables/ReportTable";
//import ReportTableMiddlewarePriced from "./tables/ReportTableMiddlewareP";
import ReportError from "./tables/ReportTableError";
import ReportTableMiddlewareZeroPriced from "./tables/ReportTableMiddlewareZP";

import ReportTableYardiPriced from "./tables/ReportTableYardiPriced";
import ReportTableYardiExport from "./tables/ReportTableYardiExport";
import ReportTableYardiZeroPriced from "./tables/ReportTableYardiZeroPriced";
import UsersTable from "./tables/UsersTable";
import PivotTable from "./tables/PivotTable";

const TableContent = ({ currentPage }) => {
  return (
    <div className="table-content">
      {currentPage === 1 && <ReportTable />}
      {currentPage === 11 && <ReportTableMiddlewareZeroPriced />}
      {currentPage === 100 && <ReportError />}
      {currentPage === 50 && <ReportTableYardiPriced />}
      {currentPage === 200 && <ReportTableYardiExport />}
      {currentPage === 51 && <ReportTableYardiZeroPriced />}
      {currentPage === 2 && <UsersTable />}
      {currentPage === 3 && <PivotTable />}
    </div>
  );
};

export default TableContent;
