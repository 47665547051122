import React from "react";

import { Card } from "@lightspeed/flame/Card";
import graph from "../../assets/graph.svg";

const Dash = () => {
  return (
    <Card className="dash">
      <div className="dash-header">
        <h1>Dashboard</h1>
      </div>
      <div className="dash-main">
        <div className="dash-svg">
          <img src={graph} alt="graph" />
        </div>
        <div className="dash-cards">
          <div className="dash-card">
            <div>
              <h3>1,581.78</h3>
              <p>REVENUE</p>
            </div>
          </div>
          <div className="dash-card">
            <div>
              <h3>81.00</h3>
              <p>RECEIPTS</p>
            </div>
          </div>
          <div className="dash-card">
            <div>
              <h3>60.00</h3>
              <p>CUSTOMERS</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Dash;
