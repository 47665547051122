const initState = {
  theme: "flame",
  notify: { error: true, errorMsg: "", show: false },
  downloadShops: false,
  downloadReports: false,
  downloadReports2: false,
  downloadResidents: false,
  downloadDistinct: false,
  search: "",
  overLimit: false,
  meals: true,
  other: true,
  locationFilter: "",
};

const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_UI_THEME":
      if (state.theme === "flame") {
        localStorage.setItem("theme", JSON.stringify("experimentaldark"));
        return { ...state, theme: "experimentaldark" };
      } else {
        localStorage.setItem("theme", JSON.stringify("flame"));
        return { ...state, theme: "flame" };
      }
    case "SET_LOCATION_FILTER":
      return { ...state, locationFilter: action.payload };
    case "SET_DOWNLOAD_SHOPS":
      return { ...state, downloadShops: action.payload };
    case "SET_DOWNLOAD_REPORTS":
      return { ...state, downloadReports: action.payload };
    case "SET_DOWNLOAD_REPORTS_2":
      return { ...state, downloadReports2: action.payload };
    case "SET_DOWNLOAD_RESIDENTS":
      return { ...state, downloadResidents: action.payload };
    case "SET_DOWNLOAD_DISTINCT":
      return { ...state, downloadDistinct: action.payload };
    case "SET_OVERLIMIT":
      return { ...state, overLimit: action.payload };
    case "SET_MEALS":
      return { ...state, meals: action.payload };
    case "SET_OTHER":
      return { ...state, other: action.payload };
    case "SET_UI_THEME":
      return { ...state, theme: action.payload };
    case "SET_NOTIFY":
      return {
        ...state,
        notify: {
          error: action.payload.error,
          errorMsg: action.payload.msg,
          show: true,
        },
      };
    case "SET_NOTIFY_SHOW":
      return {
        ...state,
        notify: {
          ...state.notify,
          show: false,
        },
      };
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

export default uiReducer;
