import React from "react";

import SideListButton from "./sideBar/SideListButton";
import DarkMode from "./sideBar/DarkMode";
import card from "../../assets/card.svg";
import chart from "../../assets/chart.svg";
import clipboard from "../../assets/clipboard.svg";

import logo from "../../assets/logo.png";

import UserDisplay from "./sideBar/UserDispaly";
import Excell from "./sideBar/Excell";
import PivotButton from "./sideBar/PivotButton";
import { useSelector } from "react-redux";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const SideBar = ({ currentPage, setCurrentPage }) => {
  const database = useSelector((state) => state.database);
  const exportState = useSelector((state) => state.exportState);

  return (
    <div className="side-bar relative">
      <div className="side-bar-logo-wrapper">
        <img src={logo} alt="logo" width="32px" height="32px" />
      </div>
      <UserDisplay />
      <div className="sidebar-list-wrapper">
        <SideListButton
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageN={0}
          label="Dashboard"
          svg={chart}
        />
        <SideListButton
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageN={1}
          label="Reports Middleware P"
          svg={clipboard}
        />
         <SideListButton
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageN={11}
          label="Reports Middleware ZP"
          svg={clipboard}
        />
        <SideListButton
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageN={50}
          label="Reports Yardi P"
          svg={clipboard}
        />
        <SideListButton
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageN={51}
          label="Reports Yardi ZP"
          svg={clipboard}
        />
        <SideListButton
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageN={100}
          label="Error Report"
          svg={clipboard}
        />

      <SideListButton
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageN={200}
          label="CSV Report"
          svg={clipboard}
        />
      
          
      </div>
      <div className="flexed" />
      <DarkMode />

      {currentPage === 1 && (
        <>
          <ExcelFile element={<Excell />}>
            <ExcelSheet data={exportState.exportReports} name="Reports">
              <ExcelColumn label="Company Id" value="company_id" />
              <ExcelColumn label="Resident Id" value="resident_id" />
              <ExcelColumn label="Resident Name" value="resident_name" />
              <ExcelColumn label="Property Name" value="comunity_name" />
              <ExcelColumn label="Charge Name" value="charge_name" />
              <ExcelColumn label="Create Time" value="create_time" />
              <ExcelColumn label="Service Name" value="service_name" />
              <ExcelColumn label="Running Total" value="running_total" />
              <ExcelColumn label="Charge Code" value="chargecode" />
              <ExcelColumn label="Total Price" value="total_price" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Srv" value="srv" />
              <ExcelColumn label="Days in Month" value="days_in_month" />
              <ExcelColumn label="Exceded" value="exceded" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
      {currentPage === 2 && (
        <ExcelFile element={<Excell />}>
          <ExcelSheet data={database.residents} name="Residents">
            <ExcelColumn label="Company Id" value="company_id" />
            <ExcelColumn label="Resident Id" value="resident_id" />
            <ExcelColumn label="Yardi Id" value="yardi_id" />
            <ExcelColumn label="Community Name" value="comunity_name" />
            <ExcelColumn label="Service Name" value="service_name" />
            <ExcelColumn label="Resident Name" value="resident_name" />
            <ExcelColumn label="Unit Id" value="unit_id" />
            <ExcelColumn label="Lightspeed Id" value="lightspeed_id" />
          </ExcelSheet>
        </ExcelFile>
      )}
      {(currentPage === 1 || currentPage === 3) && (
        <PivotButton
          setCurrentPage={setCurrentPage}
          isActive={currentPage === 3}
        />
      )}
    </div>
  );
};

export default SideBar;
