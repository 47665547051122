//import React, { useState } from "react";
import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";
import TBody from "./reportTable/TBody";
import Loader from "../../Loader";
import { useSelector } from "react-redux";
import TBodyYardiExport from "./reportTable/TBodyYardiExport";

const ReportTableYardiExport = () => {
  const ui = useSelector((state) => state.ui);
  
  if (ui.downloadReports) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table className={ui.theme === "flame" ? "" : "root-table-dark"}>
        <Thead>
          <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
            <Th className="table-th-sticky" width="10%">
              CompanyId
            </Th>
            <Th className="table-th-sticky" width="20%">
              Location ID
            </Th>
            <Th className="table-th-sticky" width="20%">
              Resident ID
            </Th>
            <Th className="table-th-sticky" width="20%">
              Resident Name 
            </Th>
            <Th className="table-th-sticky" width="20%">
              Receipt ID
            </Th>
            <Th className="table-th-sticky" width="20%">
              Time
            </Th>
            <Th className="table-th-sticky" width="20%">
              Total
            </Th>
            <Th className="table-th-sticky" width="20%">
              Charge Code
            </Th>
          </Tr>
        </Thead>
        <TBodyYardiExport />
      </Table>
    </Card>
  );
};

export default ReportTableYardiExport;
