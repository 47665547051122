const initState = {
  userId: "",
  companyId: "",
  authToken: "wqeafr34w=?*{}][2wSDFA436$%&T$#swts4eEW$T",
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        userId: action.payload.user_id,
        companyId: action.payload.company_id,
      };
    default:
      return state;
  }
};

export default userReducer;
