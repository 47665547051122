const initState = {
  exportReports: [],
};

const exportReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_EXPORT_REPORTS":
      return { ...state, exportReports: action.payload };
    default:
      return state;
  }
};

export default exportReducer;
