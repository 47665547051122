//import React, { useState } from "react";
import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";
import TBodyMiddlewareZeroPriced from "./reportTable/TBodyMiddlewareZeroPriced";
import Loader from "../../Loader";
import { useSelector } from "react-redux";

const ReportTable = () => {
  const ui = useSelector((state) => state.ui);

  if (ui.downloadReports) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table className={ui.theme === "flame" ? "" : "root-table-dark"}>
        <Thead>
          <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
            <Th className="table-th-sticky" width="10%">
              CompanyId MZP
            </Th>
            <Th className="table-th-sticky" width="20%">
              PropertyName
            </Th>
            <Th className="table-th-sticky" width="20%">
              ResidentId
            </Th>
            <Th className="table-th-sticky" width="10%">
              ResidentName
            </Th>
            <Th className="table-th-sticky" width="10%">
              CareLevel
            </Th>
            <Th className="table-th-sticky" width="10%">
              MealPlan
            </Th>
            <Th className="table-th-sticky" width="10%">
              Accountprofile
            </Th>
            <Th className="table-th-sticky" width="10%">
              ReceiptId
            </Th>
            <Th className="table-th-sticky" width="10%">
              PaymentId
            </Th>
            <Th className="table-th-sticky" width="10%">
              PaymentName
            </Th>
            <Th className="table-th-sticky" width="10%">
              CreationTime
            </Th>
            <Th className="table-th-sticky" width="10%">
              Shift
            </Th>
            <Th className="table-th-sticky" width="10%">
              RunningTotal
            </Th>
          </Tr>
        </Thead>
        <TBodyMiddlewareZeroPriced />
      </Table>
    </Card>
  );
};

export default ReportTable;
