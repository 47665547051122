//import React, { useState } from "react";
import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";
import TBodyError from "./reportTable/TBodyError";
import Loader from "../../Loader";
import { useSelector } from "react-redux";


const ReportError = () => {
  const ui = useSelector((state) => state.ui);
  
  if (ui.downloadReports) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table className={ui.theme === "flame" ? "" : "root-table-dark"}>
        <Thead>
          <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
            <Th className="table-th-sticky" width="10%">
              CompanyId E
            </Th>
            <Th className="table-th-sticky" width="20%">
              Resident Name
            </Th>

            <Th className="table-th-sticky" width="20%">
              Email
            </Th>

            <Th className="table-th-sticky" width="20%">
              Description
            </Th>
            

          </Tr>
        </Thead>
        <TBodyError />
      </Table>
    </Card>
  );
};

export default ReportError;
